import React, { useRef, useState } from "react"
import { Form, Button, Card, Alert } from "react-bootstrap"
import { useAuth } from "../contexts/authcontext"
import { Link, navigate } from "gatsby"

export default function Signup() {
  const emailRef = useRef()
  const displayNameRef = useRef()
  const passwordRef = useRef()
  const passwordConfirmRef = useRef()
  const { signup, userdetails } = useAuth()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)

  async function handleSubmit(e) {
    e.preventDefault()

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match")
    }

    try {
      setError("")
      setLoading(true)
      const displayName = displayNameRef.current.value
      const { user } = await signup(
        emailRef.current.value,
        passwordRef.current.value
      )
      await userdetails(user, { displayName })
    } catch {
      return setError("Failed to create an account")
    }

    setLoading(false)
    navigate("/purchase")
  }

  return (
    <>
      <Card className="w-100 mt-3" style={{ maxWidth: `45rem` }}>
        <Form
          name="signupform"
          onSubmit={handleSubmit}
          style={{ background: `transparent`, border: `none`, padding: `0` }}
        >
          <Card.Header
            className="m-3 p-3 text-center"
            style={{ textShadow: `0.25rem 0.25rem #412456` }}
          >
            {" "}
            <h2>Create Profilep</h2>
          </Card.Header>
          <Card.Body>
            {error && <Alert variant="danger">{error}</Alert>}

            <Form.Group id="displayName">
              <Form.Label>First Name</Form.Label>
              <Form.Control type="text" ref={displayNameRef} required />
            </Form.Group>
            <Form.Group id="email">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" ref={emailRef} required />
            </Form.Group>
            <Form.Group id="password">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" ref={passwordRef} required />
            </Form.Group>
            <Form.Group id="password-confirm">
              <Form.Label>Password Confirmation</Form.Label>
              <Form.Control type="password" ref={passwordConfirmRef} required />
            </Form.Group>
          </Card.Body>
          <Card.Footer className="m-0 p-0">
            <Button disabled={loading} className="w-100" type="submit">
              Create
            </Button>
          </Card.Footer>
        </Form>
      </Card>
      <div className="w-100 text-center mt-2 AUTH">
        <p> Already have an account? </p> <Link to="/authorise">Log In</Link>
      </div>
    </>
  )
}
