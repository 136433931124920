import React from "react"
import styled from "styled-components"
import Signup from "../components/signup"
import SEO from "../components/seo"

const StyledContainer = styled.div` 
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem auto;
`;

export default function AuthoriseNewPage() {
  return (
    <>
    <SEO title="Signup" />
    <StyledContainer>
      <Signup />
    </StyledContainer>
    </>
  )
}